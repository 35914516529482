@import "header.css";

body,
ul,
li,
p,
a {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: Arial, Microsoft YaHei, sans-serif;
}

::-webkit-scrollbar {
    width: 0;
}

a {
    text-decoration: none;
}

* {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
    vertical-align: middle;
}