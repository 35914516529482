

#ribbon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

#ribbon.case-detail-mode {
    position: absolute;
    height: 112.5vw;
    min-height: 83.333vw;
}

#pages {
    position: relative;
    padding: 0;
    transition: filter 0.5s;
}

#pages li {
    height: 100vh;
    overflow: hidden;
}

