.team .swiper-slide .info .advisor {
  padding: 24px 16px;
  width: 90%;
}

.team .swiper-slide .info .user {
  display: flex;
  height: 120px;
  align-items: center;
}

.team .swiper-slide .info .user .name {
  margin-left: 16px;
  display: flex;
  flex-direction: column;

  font-size: 16px;
  font-weight: 500;
}

.team .swiper-slide .info .user .name .job-title {
  margin-top: 8px;
}

.team .swiper-slide .info .tags {
  height: 160px;

  display: flex;
  flex-direction: column;
}

.team .swiper-slide .info .tags .ant-tag {
  margin-top: 8px;
  inline-size: max-content;
}

.team .swiper-slide {
  width: 360px !important;
}

.team .people-list {
  display: flex;
  overflow-x: auto;
  max-width: 100%;
}

.team .people-list .swiper-slide {
  margin: 0 12px;
  flex-shrink: 0;
}

.homein .join .content .allItems .item .d2 .info .sj {
  display: flex;
  justify-content: space-between;
}
.homein .join .content .allItems .item .d2 .info .sj .sj-emali {
  margin-left: 4px;
}

@media screen and (max-width: 998px) {
  .team .swiper-slide {
    width: 100% !important;
  }

  .homein .join .content .allItems .item .d2 .info .sj {
    display: flex;
    flex-direction: column-reverse;
  }
  .homein .join .content .allItems .item .d2 .info .sj .sj-box {
    margin-bottom: 16px;
  }
}

.team .join .t {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-weight: 700;
}

.team .section .honor .t {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-weight: 700;
}

/* .team .section {

  transform: scale(1);
  position: relative;
} */

.team .join-box {
  max-width: 90%;
  margin: auto;
}
