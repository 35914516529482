.home {
  overflow: hidden;
  position: relative;
}
.home .section {
  max-width: 90%;
  margin: 120px 200px 40px 200px;
  transform: scale(1);
  min-height: 80vh;
}
.home .section .menu {
  text-align: right;
}
.home .section .menu a {
  color: #707070;
}
.home .section .menu span {
  color: #f5ac24;
}
.home .section .t {
  text-align: center;
  font-size: 24px;
}
.home .section .swiper {
  width: 100%;
  margin-top: 60px;
  position: relative;
}
.home .section .swiper .bg {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  animation: move 6s linear infinite;
  pointer-events: none;
}
.home .section .swiper .bg img {
  height: 100%;
}
@keyframes move {
  0% {
    transform: translate(-50%, -50%);
  }
  25% {
    transform: translate(-48%, -48%);
  }
  50% {
    transform: translate(-50%, -50%);
  }
  75% {
    transform: translate(-60%, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
.home .section .swiper .swiper-container {
  width: 100%;
  height: 600px;
}
.home .section .swiper .swiper-container .swiper-wrapper .swiper-slide {
  overflow: hidden;
}
.home .section .swiper .swiper-container .swiper-wrapper .swiper-slide a {
  display: flex;
  width: 40%;
  justify-content: space-between;
}
.home .section .swiper .swiper-container .swiper-wrapper .swiper-slide a .text {
  width: 48%;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .text
  .pt {
  color: #707070;
  font-size: 20px;
  text-align: right;
  transition: all 600ms;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .text
  .time {
  color: #707070;
  font-size: 14px;
  margin: 10px 0;
  text-align: right;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .text
  .des {
  color: #707070;
  line-height: 1.6;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.home .section .swiper .swiper-container .swiper-wrapper .swiper-slide a .pic {
  width: 48%;
  position: relative;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .pic
  img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: all 600ms;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .pic
  .line {
  width: 105px;
  height: 1px;
  background: #001a72;
  position: absolute;
  right: -105px;
  top: 50%;
  transform: translateY(-50%);
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .pic
  .line::before {
  width: 20px;
  height: 20px;
  background: rgba(0, 26, 114, 0.39);
  border-radius: 50%;
  content: "";
  display: block;
  position: absolute;
  left: 120px;
  top: -10px;
  z-index: 5;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a
  .pic
  .line::after {
  width: 54px;
  height: 54px;
  content: "";
  background: rgba(0, 26, 114, 0.2);
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 103px;
  top: -27px;
  animation: big 2s linear infinite;
}
@keyframes big {
  0% {
    transform: scale(0.4);
  }
  25% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.4);
  }
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  a:hover
  .pic
  img {
  transform: scale(1.05);
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide:nth-child(2n)
  a {
  padding-left: 60%;
  flex-direction: row-reverse;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide:nth-child(2n)
  a
  .pt,
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide:nth-child(2n)
  a
  .time {
  text-align: left;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide:nth-child(2n)
  a
  .pic
  .line {
  right: auto;
  left: -105px;
  background: #f5aa17;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide:nth-child(2n)
  a
  .pic
  .line::before {
  right: 120px;
  left: auto;
  background: #f5aa17;
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide:nth-child(2n)
  a
  .pic
  .line::after {
  right: 103px;
  left: auto;
  background: rgba(245, 170, 23, 0.2);
}
.home
  .section
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide-active
  a
  .text
  .pt {
  color: #03167c;
}

@media screen and (max-width: 1500px) {
  .home .section {
    margin: 100px 80px;
  }
}
@media screen and (max-width: 998px) {
  .home .section {
    margin: 80px auto;
  }
  .home .section .t {
    font-size: 22px;
    margin: 30px 0;
  }
  .home .section .swiper {
    margin-top: 0;
  }
  .home .section .swiper .bg {
    display: none;
  }
  .home .section .swiper .swiper-container {
    width: 100%;
    height: auto;
  }
  .home .section .swiper .swiper-container .swiper-wrapper .swiper-slide a {
    width: 100%;
    padding: 0 !important;
    flex-direction: column !important;
  }
  .home
    .section
    .swiper
    .swiper-container
    .swiper-wrapper
    .swiper-slide
    a
    .text {
    width: 100%;
  }
  .home
    .section
    .swiper
    .swiper-container
    .swiper-wrapper
    .swiper-slide
    a
    .text
    .pt {
    font-size: 20px;
  }
  .home
    .section
    .swiper
    .swiper-container
    .swiper-wrapper
    .swiper-slide
    a
    .text
    .pt,
  .home
    .section
    .swiper
    .swiper-container
    .swiper-wrapper
    .swiper-slide
    a
    .text
    .time {
    text-align: left !important;
  }
  .home
    .section
    .swiper
    .swiper-container
    .swiper-wrapper
    .swiper-slide
    a
    .pic {
    width: 100%;
    margin-top: 20px;
  }
  .home
    .section
    .swiper
    .swiper-container
    .swiper-wrapper
    .swiper-slide
    a
    .pic
    .line {
    display: none;
  }
}

/*# sourceMappingURL=sfgj.css.map */
