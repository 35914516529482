.homen .section {
  max-width: 90%;
  margin: 120px 200px 60px 200px;
  transform: scale(1);
}
.homen .section .menu {
  text-align: right;
}
.homen .section .menu a {
  color: #707070;
}
.homen .section .menu span {
  color: #f5ac24;
}
.homen .section .t1 {
  text-align: center;
  font-size: 48px;
  font-weight: bolder;
  margin-top: 20px;
}
.homen .section .list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.homen .section .list a {
  display: block;
  width: 30%;
  margin-left: 2%;
  color: #000000;
  border: 1px solid #e7e7e7;
  margin-bottom: 60px;
  border-radius: 20px;
}
.homen .section .list a .pic {
  overflow: hidden;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.homen .section .list a .pic img {
  width: 100%;
  height: 240px;
  object-fit: cover;
  transition: all 600ms;
}
.homen .section .list a .text {
  padding: 20px;
}
.homen .section .list a .text .pt {
  font-size: 20px;
  transition: all 600ms;
}
.homen .section .list a .text .des {
  line-height: 1.7;
  margin: 20px 0 40px 0;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #7e7e7e;
}
.homen .section .list a .text .tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homen .section .list a .text .tag .time {
  color: #acacac;
}
.homen .section .list a .text .tag .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 600ms;
}
.homen .section .list a:hover .pic img {
  transform: scale(1.05);
}
.homen .section .list a:hover .text .pt {
  color: #00197c;
}
.homen .section .list a:hover .text .tag .icon {
  border-color: #00197c;
}
.homen .section .pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: 40px auto;
}
.homen .section .pager .prev {
  background: #f3f3f3;
  display: flex;
  width: 100px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.5);
  transition: all 600ms;
}
.homen .section .pager .prev img {
  width: 15px;
  display: block;
  opacity: 0.5;
  transform: rotateY(180deg);
  transition: all 600ms;
}
.homen .section .pager .prev:hover {
  color: black;
}
.homen .section .pager .prev:hover img {
  opacity: 1;
}
.homen .section .pager .nums {
  width: 80%;
  display: flex;
  justify-content: center;
}
.homen .section .pager .nums a {
  width: 40px;
  display: block;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: 1px solid transparent;
  transition: all 600ms;
  color: black;
  font-size: 18px;
  margin: 0 10px;
}
.homen .section .pager .nums a:hover {
  border-color: black;
}
.homen .section .pager .nums .active {
  border-color: black;
}
.homen .section .pager .next {
  background: #f3f3f3;
  display: flex;
  width: 100px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  color: rgba(0, 0, 0, 0.5);
  transition: all 600ms;
}
.homen .section .pager .next img {
  width: 15px;
  display: block;
  opacity: 0.5;
  transition: all 600ms;
}
.homen .section .pager .next:hover {
  color: black;
}
.homen .section .pager .next:hover img {
  opacity: 1;
}

@media screen and (max-width: 1500px) {
  .homen .section {
    margin: 100px 80px;
  }
}
@media screen and (max-width: 998px) {
  .homen .section {
    margin: 80px auto;
  }
  .homen .section .t1 {
    font-size: 24px;
  }
  .homen .section .list {
    margin-top: 20px;
  }
  .homen .section .list a {
    margin-left: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .homen .section .list a .pic img {
    height: auto;
  }
  .homen .section .list a .text {
    padding: 10px;
  }
  .homen .section .list a .text .pt {
    font-size: 18px;
  }
  .homen .section .list a .text .des {
    margin: 15px 0;
  }
  .homen .section .list a .text .tag .icon {
    width: 35px;
    height: 35px;
  }
  .homen .section .pager {
    margin: 20px auto;
    flex-direction: column;
  }
  .homen .section .pager .nums {
    margin: 15px 0;
  }
}

/*# sourceMappingURL=news.css.map */
