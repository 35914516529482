.homes {
  position: relative;
  overflow: hidden;
}
.homes .section {
  margin: 120px 200px;
  max-width: 90%;
  transform: scale(1);
  min-height: 75vh;
}
.homes .section .menu {
  text-align: right;
}
.homes .section .menu a {
  color: #707070;
}
.homes .section .menu span {
  color: #f5ac24;
}
.homes .section .content {
  width: 70vw;
  display: flex;
  justify-content: space-between;
  margin: 10vh auto;
  align-items: center;
  cursor: pointer;
}
.homes .section .allItems .content:nth-child(2n) {
  flex-direction: row-reverse;
}
.homes .section .content .text {
  width: 46%;
}
.homes .section .content .text .pt {
  font-size: 18px;
}
.homes .section .content .text .des {
  color: #707070;
  margin-top: 16px;
  line-height: 1.8;
  font-size: 14px;
}
.homes .section .content .text .des p {
  margin-top: 16px;
}
.homes .section .content .pic {
  width: 50%;
  /* background: white; */
  /* padding: 30px; */
}
.homes .section .content .pic img {
  max-width: 100%;
  display: block;
  object-fit: contain;
}

@media screen and (max-width: 1500px) {
  .homes .section {
    margin: 100px 80px;
  }
  .homes .section .content {
    width: 100%;
  }
}
@media screen and (max-width: 998px) {
  .homes .section {
    margin: 80px auto;
    height: auto;
    padding-bottom: 60px;
  }
  .homes .section .content {
    width: 100%;
    flex-direction: column !important;
    margin: 20px auto;
  }
  .homes .section .content .text {
    width: 100%;
  }
  .homes .section .content .text .pt {
    font-size: 18px;
  }
  .homes .section .content .text .des {
    margin-top: 14px;
    font-size: 14px;
  }
  .homes .section .content .pic {
    width: 100%;
    margin: 20px 0;
  }
}

/*# sourceMappingURL=solution.css.map */
