.tec-img {
  width: 800px;
}

.title-box {
  margin: 40px 0;
  display: flex;
  align-items: flex-end;
  position: relative;
}
.title-box .title {
  font-size: 24px;
  font-weight: 600;
}
.title-box .title-subscript {
  font-size: 14px;
  color: #333;
  margin-left: 4px;
}
