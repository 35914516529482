.header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: calc(100% - 400px);
  padding: 20px 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .about {
  width: 165px;
  height: 42px;
  background: white;
  box-shadow: 3px 3px 10px rgba(0, 25, 124, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.header .about .circle {
  width: 14px;
  height: 14px;
  border: 3px solid #ec9510;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .about .circle::before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background: radial-gradient(
    circle,
    #000cfc 0%,
    #000eef 25%,
    #000fe0 66%,
    #0010db 100%
  );
  border-radius: 50%;
}
.header .about p {
  margin-left: 5px;
  color: #00197c;
  font-size: 20px;
}

.copyright {
  position: fixed;
  left: 45px;
  top: 30%;
  transform: translateY(-50%);
  color: #d9d9d9;
  font-size: 14px;
  z-index: 5;
}
.copyright p {
  transform: rotate(90deg);
  transform-origin: top left;
}

.links {
  position: fixed;
  left: 15px;
  bottom: 40px;
  z-index: 5;
}
.links a {
  display: block;
  margin-bottom: 20px;
  transition: all 600ms;
  position: relative;
}
.links a .code {
  position: absolute;
  left: 100%;
  top: -50px;
  padding-left: 10px;
  transform: scale(0);
  transition: all 600ms;
}
.links a .code img {
  width: 100px;
  height: 100px;
  display: block;
  object-fit: contain;
}
.links a:hover {
  transform: scale(1.1);
}
.links a:hover .code {
  transform: scale(1);
}

@media screen and (max-width: 1500px) {
  .header {
    width: calc(100% - 160px);
    padding: 20px 80px;
  }
}
@media screen and (max-width: 1100px) {
  .header {
    width: 90%;
    padding: 20px 0;
    left: 5%;
  }
  .header a:first-child img {
    width: 120px;
    object-fit: contain;
  }
  .header .about {
    width: 160px;
    height: 40px;
  }
  .header .about p {
    font-size: 16px;
  }

  .copyright {
    display: none;
  }
}

/*# sourceMappingURL=header.css.map */
