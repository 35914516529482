.homet .section {
  max-width: 90%;
  margin: 100px 200px 100px 200px;
  transform: scale(1);
  text-align: center;
}
.homet .section .menu {
  text-align: right;
}
.homet .section .menu a {
  color: #707070;
}
.homet .section .menu span {
  color: #f5ac24;
}
.homet .section svg {
  max-width: 100%;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  transform: scale(0.9) translateY(-60px);
}

@media screen and (max-width: 1500px) {
  .homet .section {
    margin: 100px 80px;
  }
}
@media screen and (max-width: 998px) {
  .homet .section {
    margin: 80px auto;
  }
  .homet .section svg {
    height: 500px;
  }
}

/*# sourceMappingURL=tech.css.map */
