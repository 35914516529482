/* .homein .join .t {
  font-size: 32px;
  text-align: center;
}

.homein .section .honor .t {
  font-size: 32px;
  text-align: center;
} */
/* .company .section {
  max-width: 90%;
  margin: 120px 200px 60px 200px;
  transform: scale(1);
  position: relative;
} */

.botm-pic {
  display: flex;
  margin-top: 40px;
}
.botm-pic img {
  width: 100%;
}
