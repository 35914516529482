.fl-two {
  font-size: 14px;
  text-align: center;
  margin: 40px 0px 40px 0px;
  display: flex;
  justify-content: center;
}
.fl-box {
  position: absolute;
  display: flex;
}
.fl-box span {
  color: #d9d9d9;
}
.fl-box a {
  color: #d9d9d9;
  margin-left: 8px;
}
.fl-box a:hover {
  text-decoration: underline;
  color: #00197c;
}
.fl-box .pl {
  margin-left: 8px;
  display: flex;
  align-items: center;
}

.fl-box .fl-icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

@media screen and (max-width: 1100px) {
  .fl-two {
    height: 40px;
    margin: 0px 0px 40px 0px;
    padding-top: 20px;
  }
  .fl-box {
    display: flex;
    flex-direction: column;
  }
}
