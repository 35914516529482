.homei {
  position: relative;
}
.homei .section {
  margin: 120px 0px 20px 0px;
  transform: scale(1);
  min-width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 80vh;
}
.homei .section .title {
  text-align: center;
  font-size: 18px;
}
.homei .section .time {
  text-align: center;
  font-size: 14px;
  color: #7e7e7e;
  padding: 30px 0;
  border-bottom: 1px solid #cccccc;
  /* min-width: 90%; */
  width: 720px;
}
.homei .section .html {
  margin: 30px 0;
  line-height: 1.8;
  font-size: 14px;
  width: 720px;
  min-height: 80vh;
}

@media screen and (max-width: 1500px) {
  .homei .section {
    margin: 100px 80px 20px 80px;
  }
}
@media screen and (max-width: 998px) {
  .homei .section {
    margin: 80px auto;
  }
  .homei .section .title {
    font-size: 18px;
  }
  .homei .section .time {
    padding: 14px 0;
  }
  .homei .section .html {
    margin: 15px 0;
    font-size: 14px;
    width: 95%;
    padding: 0px 8px;
  }
  p img {
    width: 98% !important;
    height: auto !important;
  }
}
.article-end {
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #cccccc;
}
.article-end div {
  border-bottom: 1px solid #cccccc;
  width: 45%;
}
/*# sourceMappingURL=info.css.map */
.wangEditor-container table th {
  border: 1px solid #ccc;
  line-height: 1.5;
  padding: 3px 5px;
  min-width: 50px;
  height: 20px;
  background-color: #f5f2f0;
}
.wangEditor-container table {
  border: none;
  border-collapse: collapse;
}
.wangEditor-container table td {
  border: 1px solid #ccc;
  padding: 3px 5px;
  min-width: 50px;
  height: 20px;
}
