.about-us {
  position: fixed;
  z-index: 99;
  left: 12px;
  top: 35%;
}

.about-us .about {
  width: 42px;
  height: 146px;
  background: white;
  box-shadow: 3px 3px 10px rgba(0, 25, 124, 0.15);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.about-us .about .circle {
  width: 14px;
  height: 14px;
  border: 3px solid #ec9510;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us .about .tx {
  margin-top: 8px;
  width: 20px;
  color: #00197c;
  font-size: 20px;

  line-height: 24px;
}

.about-us .about .circle:before {
  background: radial-gradient(
    circle,
    #000cfc 0,
    #000eef 25%,
    #000fe0 66%,
    #0010db 100%
  );
  border-radius: 50%;
  content: "";
  display: block;
  height: 6px;
  width: 6px;
}
