.homezy {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}
.homezy .section {
  margin: 120px 200px 0 200px;
  transform: scale(1);
}
.homezy .section .d1 {
  display: flex;
  justify-content: space-between;
}
.homezy .section .d1 .item {
  width: 26%;
  position: relative;
  cursor: pointer;
  transition: all 600ms;
  overflow: hidden;
}
.homezy .section .d1 .item .bg {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  transition: all 1s;
}
.homezy .section .d1 .item .tag {
  position: absolute;
  left: 5%;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 90%;
  transition: all 600ms;
  z-index: 11;
}
.homezy .section .d1 .item .tag p {
  font-size: 20px;
  transition: all 600ms;
}
.homezy .section .d1 .item .tag div {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translateY(20px);
  transition: all 600ms;
}
.homezy .section .d1 .item .tag div p {
  margin-right: 20px;
  font-size: 16px;
}
.homezy .section .d1 .item .tag div img {
  width: 28px;
  transition: all 600ms;
}
.homezy .section .d1 .item .tag div:hover {
  color: white;
}
.homezy .section .d1 .item .tag div:hover img {
  transform: translateX(-10px);
}
.homezy .section .d1 .item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 600ms;
  z-index: 10;
}
.homezy .section .d1 .item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.homezy .section .d1 .item:hover .bg {
  transform: scale(1.05);
}
.homezy .section .d1 .item:hover::before {
  opacity: 1;
}
.homezy .section .d1 .item:hover .tag {
  transform: translateY(-10px);
}
.homezy .section .d1 .item:hover .tag div {
  opacity: 1;
  transform: translateY(0);
}
.homezy .section .d1 .item:nth-child(1) {
  width: 35%;
}
.homezy .section .d1 .item:nth-child(3) {
  width: 38%;
}
.homezy .section .d2 {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
}
.homezy .section .d2 .item {
  width: 38%;
  transition: all 600ms;
  position: relative;
  overflow: hidden;
}
.homezy .section .d2 .item .bg {
  width: 100%;
  height: 32vh;
  object-fit: cover;
  transition: all 1s;
}
.homezy .section .d2 .item .tag {
  position: absolute;
  left: 5%;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 90%;
  transition: all 600ms;
  z-index: 11;
}
.homezy .section .d2 .item .tag p {
  font-size: 20px;
  transition: all 600ms;
}
.homezy .section .d2 .item .tag div {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translateY(20px);
  transition: all 600ms;
}
.homezy .section .d2 .item .tag div p {
  margin-right: 20px;
  font-size: 16px;
}
.homezy .section .d2 .item .tag div img {
  width: 28px;
  transition: all 600ms;
}
.homezy .section .d2 .item .tag div:hover {
  color: white;
}
.homezy .section .d2 .item .tag div:hover img {
  transform: translateX(-10px);
}
.homezy .section .d2 .item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 600ms;
  z-index: 11;
}
.homezy .section .d2 .item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.homezy .section .d2 .item:hover .bg {
  transform: scale(1.05);
}
.homezy .section .d2 .item:hover::before {
  opacity: 1;
}
.homezy .section .d2 .item:hover .tag {
  transform: translateY(-10px);
}
.homezy .section .d2 .item:hover .tag div {
  opacity: 1;
  transform: translateY(0);
}
.homezy .section .d2 .item:nth-child(1) {
  width: 61.5%;
}
.homezy .section2 {
  margin: 0 160px;
}
.homezy .section2 .list {
  display: flex;
  justify-content: space-between;
}
.homezy .section2 .list .item {
  width: 23%;
  text-align: center;
}
.homezy .section2 .list .item p {
  font-size: 22px;
  font-weight: bolder;
  color: #9b9b9b;
}
.homezy .section2 .list .item .aList a {
  font-size: 22px;
  font-weight: bolder;
  display: block;
  margin-top: 15px;
  color: #beb6bb;
  transition: all 600ms;
  position: relative;
}
.homezy .section2 .list .item .aList a::after {
  content: "";
  display: block;
  width: 0;
  height: 10px;
  background: rgba(242, 165, 20, 0.6);
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  transition: all 600ms;
}
.homezy .section2 .list .item .aList a:hover {
  color: #171717;
}
.homezy .section2 .list .item .aList a:hover::after {
  width: 100px;
}

@media screen and (max-width: 1500px) {
  .homezy .section,
  .homezy .section2 {
    margin: 0 80px;
  }
  .homezy .section {
    margin-top: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .homezy {
    height: auto;
  }
  .homezy .section {
    width: 90%;
    margin: 80px auto 0 auto;
  }
  .homezy .section .d1,
  .homezy .section .d2 {
    flex-wrap: wrap;
  }
  .homezy .section .d1 .item,
  .homezy .section .d2 .item {
    width: 49% !important;
    margin-bottom: 10px;
  }
  .homezy .section .d1 .item .bg,
  .homezy .section .d2 .item .bg {
    height: 200px;
  }
  .homezy .section .d1 .item .tag,
  .homezy .section .d2 .item .tag {
    flex-direction: column;
    align-items: flex-start;
  }
  .homezy .section .d1 .item .tag p,
  .homezy .section .d2 .item .tag p {
    font-size: 16px;
  }
  .homezy .section .d1 .item .tag a,
  .homezy .section .d2 .item .tag a {
    opacity: 1;
  }
  .homezy .section .d1 .active,
  .homezy .section .d2 .active {
    width: 100%;
  }
  .homezy .section .d2 {
    margin-top: 0;
  }
  .homezy .section .d2 .item {
    width: 100%;
  }
  .homezy .section2 {
    width: 90%;
    margin: 0 auto;
  }
  .homezy .section2 .list {
    flex-wrap: wrap;
  }
  .homezy .section2 .list .item {
    width: 48%;
    margin-bottom: 20px;
  }
  .homezy .section2 .list .item p {
    font-size: 18px;
  }
  .homezy .section2 .list .item .aList a {
    font-size: 16px;
    margin-top: 10px;
  }
}

/*# sourceMappingURL=zy.css.map */
