.homein {
  padding-bottom: 100px;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.homein .section {
  max-width: 90%;
  margin: 120px 200px 60px 200px;
  transform: scale(1);
  position: relative;
}
.homein .section .anmation_pic1 {
  position: absolute;
  right: -19%;
  top: 20%;
}
.homein .section .anmation_pic1 img {
  width: 160px;
  animation: move 2s linear infinite;
}
@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.homein .section .intro {
  width: 100%;
  position: relative;
}
.homein .section .intro img {
  width: 100%;
  display: block;
  object-fit: cover;
  min-height: 600px;
}
.homein .section .intro .text {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translateY(-50%);
  width: 500px;
  background: #4d4444bd;
  padding: 30px;
}
.homein .section .intro .text .pt {
  font-size: 32px;
}

.homein .section .intro .text .des {
  color: #fff;
  line-height: 1.8;
}

.homein .section .intro .text .des p {
  margin-top: 16px;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16; /* 这里是超出几行省略 */
  overflow: hidden;
  white-space: break-spaces;
}
.homein .section .people {
  width: 90%;
  margin: 80px auto;
}
.homein .section .people .t {
  font-size: 32px;
  text-align: center;
}
.homein .section .people .allPeople {
  display: flex;
  flex-wrap: wrap;
}
.homein .section .people .allPeople a {
  width: 33%;
  display: flex;
  justify-content: space-between;
  color: #000000;
  margin-top: 60px;
}
.homein .section .people .allPeople a .avator img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  transition: all 600ms;
}
.homein .section .people .allPeople a .text {
  width: calc(96% - 120px);
  text-align: left;
}
.homein .section .people .allPeople a .text .name {
  font-size: 18px;
}
.homein .section .people .allPeople a .text .tag {
  margin-top: 15px;
}
.homein .section .people .allPeople a:hover .avator img {
  transform: scale(1.1);
}
.homein .section .honor {
  width: 90%;
  margin: 60px auto;
  position: relative;
}
.homein .section .honor .anmation_pic2 {
  position: absolute;
  left: -18%;
  top: 0;
  z-index: 100;
}
.homein .section .honor .anmation_pic2 img {
  transform: translateX(-26px);
}

.homein .section .honor .swiper {
  width: 100%;
  margin: 40px 0;
  position: relative;
}
.homein .section .honor .swiper .prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) rotateY(180deg);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  opacity: 0.5;
  transition: all 600ms;
}
.homein .section .honor .swiper .prev img {
  width: 20px;
}
.homein .section .honor .swiper .prev:hover {
  opacity: 1;
}
.homein .section .honor .swiper .next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  opacity: 0.5;
  transition: all 600ms;
}
.homein .section .honor .swiper .next img {
  width: 20px;
}
.homein .section .honor .swiper .next:hover {
  opacity: 1;
}
.homein .section .honor .swiper .swiper-container {
  width: 90%;
  margin: 0 auto;
}
.homein
  .section
  .honor
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  .info {
  /* border: 1px solid #cccccc; */
  /* height: 400px; */
}
.homein
  .section
  .honor
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  .info
  .pic {
  background: #fcfbfc;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homein
  .section
  .honor
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  .info
  .pic
  img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  display: block;
  transition: all 600ms;
}
.homein
  .section
  .honor
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  .info
  p {
  background: white;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #cccccc;
}
.homein
  .section
  .honor
  .swiper
  .swiper-container
  .swiper-wrapper
  .swiper-slide
  .info:hover
  .pic
  img {
  transform: scale(1.05);
}
.homein .join {
  padding: 60px 200px;
  background: #f7f7f7;
  transform: scale(1);
}

.homein .join .content {
  margin-top: 40px;
}
.homein .join .content .thead {
  padding: 60px;
  background: white;
}
.homein .join .content .thead .tt {
  display: flex;
  font-size: 30px;
  align-items: center;
}
.homein .join .content .thead .tt .p2 {
  color: #00197c;
  margin: 0 20px;
}
.homein .join .content .thead .notice {
  margin-top: 40px;
}
.homein .join .content .thead .notice .it {
  display: flex;
  margin-top: 15px;
}
.homein .join .content .thead .notice .it .rt {
  display: flex;
}
.homein .join .content .thead .notice .it .rt p {
  margin-left: 30px;
  color: #cccccc;
}
.homein .join .content .thead .notice .it .rt p:first-child {
  color: #00197c;
}
.homein .join .content .allItems .item {
  margin-top: 15px;
}
.homein .join .content .allItems .item .d1 {
  padding: 30px 60px;
  display: flex;
  justify-content: space-between;
  background: white;
  cursor: pointer;
}
.homein .join .content .allItems .item .d1 p {
  width: 20%;
  color: #a79f9f;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.homein .join .content .allItems .item .d1 p:nth-child(1) {
  font-size: 18px;
  color: #000000;
}
.homein .join .content .allItems .item .d1 .icon {
  transition: all 600ms;
  opacity: 0.5;
}
.homein .join .content .allItems .item .isDown .icon {
  transform: rotate(90deg);
  opacity: 1;
}
.homein .join .content .allItems .item .d2 {
  border-top: 1px solid rgba(204, 204, 204, 0.2);
  padding: 30px 60px;
  background: white;
  display: none;
}
.homein .join .content .allItems .item .d2 .info {
  margin-bottom: 30px;
}
.homein .join .content .allItems .item .d2 .info .pt {
  font-size: 18px;
}
.homein .join .content .allItems .item .d2 .info .des {
  line-height: 1.8;
  margin-top: 15px;
}
.homein .join .content .allItems .item .d2 a {
  width: 186px;
  height: 56px;
  background: #00197c;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homein .join .content .allItems .item .d2 a p {
  color: white;
}
.homein .join .content .allItems .item .d2 a img {
  width: 26px;
  display: block;
  margin-left: 10px;
}
.homein .contact {
  max-width: 90%;
  margin: 80px 200px 80px 200px;
  transform: scale(1);
  position: relative;
}
.homein .contact .anmation_pic3 {
  position: absolute;
  right: -17%;
  top: -50px;
  z-index: 1;
  transform: translateX(8px);
}
.homein .contact .anmation_pic3 img {
  width: 120px;
  animation: move 2s linear infinite;
}
@keyframes move2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(0, 0);
  }
  75% {
    transform: translate(-5px, -5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.homein .contact .t {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}

.homein .t .t-pre {
  height: 20px;
  width: 4px;
  background: rgb(16, 140, 238);
  border-radius: 4px;
  margin-right: 4px;
}

.homein .contact .content {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.homein .contact .content .l {
  width: 35%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.homein .contact .content .l .dom1 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  height: 460px;
}
.homein .contact .content .l .dom1 .it {
  width: 30%;
  margin-bottom: 20px;
}
.homein .contact .content .l .dom1 .it .pt {
  color: #7e7e7e;
  font-size: 16px;
}
.homein .contact .content .l .dom1 .it .tag {
  color: #433c3c;
  font-size: 16px;
  margin-top: 5px;
}
.homein .contact .content .l .pic img {
  width: 100%;
  display: block;
  object-fit: cover;
}
.homein .contact .content .map {
  width: 65%;
}

@media screen and (max-width: 1500px) {
  .homein .section {
    margin: 120px 80px 60px 80px;
  }
  .homein .section .honor {
    width: 100%;
  }
  .homein .join {
    padding: 60px 80px;
  }
  .homein .contact {
    margin: 80px;
  }
  .homein .contact .content .map {
    height: 460px;
  }
}
@media screen and (max-width: 998px) {
  .homein .section {
    margin: 80px auto;
  }
  .homein .section .intro img {
    min-height: 200px;
  }
  .homein .section .intro .text {
    position: static;
    width: 100%;
    background: transparent;
    padding: 0;
    transform: translateY(0);
    margin-top: 20px;
  }
  .homein .section .intro .text .pt {
    font-size: 24px;
  }
  .homein .section .intro .text .des {
    color: #707070;
    line-height: 1.8;
  }
  .homein .section .people {
    width: 100%;
    margin: 40px auto;
  }
  .homein .section .people .t {
    font-size: 24px;
  }
  .homein .section .people .allPeople {
    justify-content: space-between;
  }
  .homein .section .people .allPeople a {
    width: 100%;
    margin-top: 15px;
    align-items: center;
  }
  .homein .section .honor {
    width: 100%;
    margin: 30px auto;
  }
  .homein .section .honor .t {
    font-size: 24px;
  }
  .homein .section .honor .swiper .prev,
  .homein .section .honor .swiper .next {
    display: none;
  }
  .homein .join {
    padding: 30px 5%;
  }
  .homein .join .t {
    font-size: 24px;
  }
  .homein .join .content .thead {
    padding: 20px;
  }
  .homein .join .content .thead .tt {
    font-size: 20px;
  }
  .homein .join .content .thead .tt .p2 {
    margin-top: 5px;
  }
  .homein .join .content .thead .notice {
    margin-top: 20px;
  }
  .homein .join .content .thead .notice .it .rt p {
    margin-left: 10px;
  }
  .homein .join .content .allItems .item .d1 {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .homein .join .content .allItems .item .d1 p {
    padding: 4px;
    width: 100%;
  }
  .homein .join .content .allItems .item .d1 p:nth-child(1) {
    font-size: 16px;
  }

  /* .homein .join .content .allItems .item .d1 p:nth-child(4) {
    position: absolute;
    bottom: 8px;
  } */

  .homein .join .content .allItems .item .d1 .icon {
    transition: all 600ms;
    opacity: 0.5;
    position: absolute;
    right: 60px;
  }
  .homein .join .content .allItems .item .isDown .icon {
    transform: rotate(90deg);
    opacity: 1;
  }

  .homein .join .content .allItems .item .d2 {
    padding: 20px;
  }
  .homein .join .content .allItems .item .d2 a {
    width: 160px;
    height: 45px;
  }
  .homein .contact {
    margin: 30px auto;
  }
  .homein .contact .t {
    font-size: 24px;
  }
  .homein .contact .content {
    margin-top: 30px;
    flex-direction: column;
  }
  .homein .contact .content .l {
    width: 100%;
  }
  .homein .contact .content .l .dom1 .it {
    width: 100%;
    margin-bottom: 10px;
  }
  .homein .contact .content .l .dom1 .it .tag {
    font-size: 16px;
  }
  .homein .contact .content .l .pic {
    margin-top: 0;
  }
  .homein .contact .content .map {
    width: 100%;
    margin-top: 20px;
    height: 300px;
  }
}

/*# sourceMappingURL=intro.css.map */
