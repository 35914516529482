.home {
  position: relative;
}
.home .section {
  margin: 0 200px;
  display: block;
  transform: translateY(120px);
}
.home .section .d1 {
  display: flex;
  justify-content: space-between;
}
.home .section .d1 .item {
  width: 13.8%;
  position: relative;
  cursor: pointer;
  margin-bottom: 0px;
  transition: all 600ms;
  overflow: hidden;
}
.home .section .d1 .item .bg {
  width: 100%;
  height: 42vh;
  object-fit: cover;
  transition: all 1s;
}
.home .section .d1 .item .tag {
  position: absolute;
  left: 5%;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 90%;
  transition: all 600ms;
  z-index: 11;
}
.home .section .d1 .item .tag p {
  font-size: 18px;
  transition: all 600ms;
}
.home .section .d1 .item .tag div {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: translateY(20px);
  transition: all 600ms;
}
.home .section .d1 .item .tag div p {
  margin-right: 20px;
  font-size: 16px;
}
.home .section .d1 .item .tag div img {
  width: 28px;
  transition: all 600ms;
}
.home .section .d1 .item .tag div:hover {
  color: white;
}
.home .section .d1 .item .tag div:hover img {
  transform: translateX(-10px);
}
.home .section .d1 .item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 600ms;
  z-index: 10;
}
.home .section .d1 .item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.home .section .d1 .item:hover .bg {
  transform: scale(1.05);
}
.home .section .d1 .item:hover::before {
  opacity: 1;
}
.home .section .d1 .item:hover .tag {
  transform: translateY(-10px);
}
.home .section .d1 .item:hover .tag div {
  opacity: 1;
  transform: translateY(0);
}
.home .section .d1 .item:nth-child(3) {
  width: 43.2%;
}
.home .section .d1 .item:nth-child(4) {
  width: 28%;
}
.home .section .d2 {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.home .section .d2 .item {
  width: 21.5%;
  transition: all 600ms;
  position: relative;
  overflow: hidden;
}
.home .section .d2 .item .bg {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  transition: all 1s;
}
.home .section .d2 .item .tag {
  position: absolute;
  left: 5%;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 90%;
  transition: all 600ms;
  z-index: 11;
}
.home .section .d2 .item .tag p {
  font-size: 18px;
  transition: all 600ms;
}
.home .section .d2 .item .tag div {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transition: all 600ms;
  transform: translateY(20px);
}
.home .section .d2 .item .tag div p {
  margin-right: 20px;
  font-size: 16px;
}
.home .section .d2 .item .tag div img {
  width: 28px;
  transition: all 600ms;
}
.home .section .d2 .item .tag div:hover {
  color: white;
}
.home .section .d2 .item .tag div:hover img {
  transform: translateX(-10px);
}
.home .section .d2 .item::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 600ms;
  z-index: 10;
}
.home .section .d2 .item:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
.home .section .d2 .item:hover .bg {
  transform: scale(1.05);
}
.home .section .d2 .item:hover::before {
  opacity: 1;
}
.home .section .d2 .item:hover .tag {
  transform: translateY(-10px);
}
.home .section .d2 .item:hover .tag div {
  opacity: 1;
  transform: translateY(0);
}
.home .section .d2 .item:nth-child(4) {
  width: 28%;
}
.home .section .d2 .item:nth-child(1) {
  width: 13.8%;
}
.home .section .d2 .item:nth-child(2) {
  width: 35.6%;
}

.home .section2 {
  position: relative;
  width: 100%;
  background: url("../images/map.png") no-repeat;
  background-size: cover;
  padding-bottom: 100px;
}
.home .section2 .module {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.home .section2 .module .slider {
  height: 410px;
  position: relative;
  width: 100%;
}
.home .section2 .module .slider li {
  background: url("../images/customer.png") no-repeat 50% / cover;
  height: 410px;
  position: absolute;
  top: 0;
  width: 1912px;
}
.home .section2 .module .slider .index0 {
  animation: move1 30s linear infinite;
  left: 0;
}
.home .section2 .module .slider .index1 {
  animation: move2 30s linear infinite;
  left: 1912px;
}
@keyframes move1 {
  0% {
    left: 0;
  }
  100% {
    left: -1912px;
  }
}
@keyframes move2 {
  0% {
    left: 1912px;
  }
  100% {
    left: 0;
  }
}
.home .section2 .hidden {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 407px;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1500px) {
  .home .section {
    margin: 0 80px;
  }
  .home .section .d1 .item .tag a p {
    margin-right: 5px;
  }
}
@media screen and (max-width: 1100px) {
  .home {
    height: auto;
  }
  .home .section {
    width: 90%;
    margin: 80px auto 0 auto;
    transform: translateY(0);
  }
  .home .section .d1,
  .home .section .d2 {
    flex-wrap: wrap;
  }
  .home .section .d1 .item,
  .home .section .d2 .item {
    width: 49% !important;
    margin-bottom: 10px;
  }
  .home .section .d1 .item .bg,
  .home .section .d2 .item .bg {
    height: 200px;
  }
  .home .section .d1 .item .tag,
  .home .section .d2 .item .tag {
    flex-direction: column;
    align-items: flex-start;
  }
  .home .section .d1 .item .tag p,
  .home .section .d2 .item .tag p {
    font-size: 16px;
  }
  .home .section .d1 .item .tag a,
  .home .section .d2 .item .tag a {
    opacity: 1;
  }
  .home .section .d2 {
    margin-top: 0;
  }
  .home .section2 {
    padding-bottom: 0;
  }
  .home .section2 .hidden {
    height: 0;
  }
}

/*# sourceMappingURL=about.css.map */
