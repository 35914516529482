.home {
  min-height: 100vh;
  overflow: hidden;
  position: relative;
}

.home .section1 {
  margin: 0 200px;
  display: flex;
  flex-wrap: wrap;
  transform: scale(1) translateY(120px);
  justify-content: space-between;
  padding-bottom: 120px;
}

.home .section1 .item {
  position: relative;
  margin-bottom: 8px;
  width: 24.6%;
  overflow: hidden;
}

.home .section1 .item img {
  width: 100%;
  display: block;
  object-fit: cover;
  transition: all 600ms;
  transform: scale(1.05);
}

.home .section1 .item .info {
  position: absolute;
  left: 0;
  top: 0;
  background: #fefefe38;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 600ms;
  opacity: 0;
}

.home .section1 .item .info .t {
  color: #00197c;
  font-size: 32px;
  position: relative;
  padding: 20px 0;
}

.home .section1 .item .info .t::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 32px;
  height: 4px;
  background: #00197c;
  transform: translateX(-50%);
}

.home .section1 .item .info .des {
  color: #00197c;
  font-size: 14px;
  line-height: 1.8;
  width: 90%;
  margin: 20px auto;

  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;

  overflow: hidden;
}

.home .section1 .item .info a {
  width: 158px;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  border-radius: 20px;
  color: white;
  line-height: 54px;
  font-size: 18px;
}

.home .section1 .item .new {
  position: absolute;
  left: 5%;
  top: 85%;

  color: #00197c;
  font-size: 20px;
  font-weight: 500;

  text-decoration: none;
}

.home .section1 .item .title_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #00197c;
  font-size: 20px;
  font-weight: 500;
}
.home .section1 .item span {
  white-space: nowrap;
}
.home .section1 .item .title_font_white {
  color: #fff;
}

.home .section1 .item:hover img {
  transform: scale(1.1);
}

.home .section1 .item:hover .info {
  opacity: 1;
}

.home .section1 .item:hover .new {
  opacity: 0.4;
}

.home .section1 .item:hover img {
  opacity: 0.35;
}

@media screen and (max-width: 1660px) {
  .home .section1 .item .info .t {
    font-size: 26px;
  }

  .home .section1 .item .info .des {
    width: 95%;
  }
}

@media screen and (max-width: 1500px) {
  .home .section1 {
    margin: 0 80px;
    transform: scale(1) translateY(100px);
  }

  .home .section1 .item .info .t {
    font-size: 20px;
  }

  .home .section1 .item .info .des {
    width: 90%;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; */
    line-height: 1.6;
    margin: 10px auto;
    font-size: 12px;
  }

  .home .section1 .item .info a {
    line-height: 40px;
  }
}

@media screen and (max-width: 1100px) {
  .home {
    height: auto;
  }

  .home .section1 {
    width: 90%;
    margin: 0 auto;
  }

  .home .section1 .item {
    width: 100%;
    margin-bottom: 15px;
  }
}

/* .mask {
  height: 100%;
  width: 100%;
  background: #6666668c;
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=index.css.map */
/* .tips {
  height: 60px;
  width: 200px;
  border-radius: 10px;
  padding: 10px;
  background: #33333357;
  color: #fff;
  display: flex;
  line-height: 1.8;
  font-size: medium;
} */
